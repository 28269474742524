@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    display: inline-block;
    width: 255px;
    background-color: var(--color-background-secondary-default);
    margin-top: var(--spacing-core-2);
    margin-right: var(--spacing-core-4);
    border-radius: var(--spacing-core-2);
    overflow: hidden;
}

.button {
    height: 0;
}

.info {
    padding: var(--spacing-core-4);
}

@include mq.mq($from: large) {
    .root {
        width: 260px;
    }
}
