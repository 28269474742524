@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    display: flex;
    margin-top: var(--spacing-core-8);
}

.icon {
    margin-right: var(--spacing-core-6);
    width: var(--sizing-core-10);
    height: var(--sizing-core-10);
}

.tips {
    display: flex;
    flex-direction: column;

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }
}

.textTips {
    padding-top: var(--spacing-core-2);
}

.type {
    text-transform: capitalize;
    padding-right: var(--spacing-core-2);
    font-weight: 800;
}

.videoTipsWrapper {
    display: flex;
    flex-flow: row wrap;
}

@include mq.mq($from: large) {
    .icon {
        min-width: var(--sizing-core-12);
        height: var(--sizing-core-12);
    }
}
