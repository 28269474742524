.root {
    // stylelint-disable-next-line custom-property-pattern
    font-family: var(--typography-body-regular-desktop-fontFamily);
    color: var(--color-foreground-primary-default);
    line-height: 150%;

    a {
        color: inherit;
        text-decoration: none;
        border-bottom: 1px dotted var(--color-background-inverted-default);

        &:visited {
            color: inherit;
        }
    }

    p, 
    h1, 
    h2, 
    h3, 
    h4, 
    h5, 
    h6 {
        margin: 0;
    }
}
